import {
  CarouselContainerWithScrollBar,
  SectionContainer,
  SliderSectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { StaticQuery, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
import { trainingSessionSlide } from "./styles/rctp-styles";
import {
  FancyTitleMed,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";

import RhombusButton from "components/common/RhombusButton";
import SlideInContainer from "components/common/SlideInContainer";
import TrainingEvent from "./TrainingEvent";
import { checkLastDate, compareStartDates } from "./util/rctp-functions";

export default function UpComingTrainings() {
  const renderContent = data => {
    const { edges: _events } = data.allMarkdownRemark;

    const [events, setEvents] = useState(_events);

    useEffect(() => {
      const filteredEvents = _events
        .filter(checkLastDate)
        .sort(compareStartDates);
      setEvents(filteredEvents.slice(0, 3));
    }, [_events]);

    return (
      <SectionContainer
        style={{
          paddingLeft: "0",
          paddingRight: "0",
          overflow: "visible",
        }}
      >
        <SliderSectionInner>
          <TextContainer
            style={{
              textAlign: "center",
              width: "931px",
              maxWidth: "calc(100% - 1.25rem)",
            }}
          >
            <SlideInContainer>
              <FancyTitleMed>Upcoming Trainings</FancyTitleMed>
            </SlideInContainer>
            <MainParagraph>
              Our RCTP trainings are held in multiple cities across the US and
              Canada. Find a session near you, RSVP today, and unlock the path
              to becoming a Rhombus security expert. Limited seats are available
              so don’t wait.  
            </MainParagraph>
          </TextContainer>
          <CarouselContainerWithScrollBar>
            <Swiper
              slidesPerView="auto"
              centeredSlides
              centeredSlidesBounds
              spaceBetween={40}
              scrollbar={{ draggable: true }}
              modules={[Scrollbar]}
            >
              {events.map(event => {
                return (
                  <SwiperSlide
                    className={trainingSessionSlide}
                    key={event.node.id}
                  >
                    <TrainingEvent eventData={event} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </CarouselContainerWithScrollBar>
          <RhombusButton
            type="primary"
            title="View All Locations"
            path="/rctp-program/training-sessions#event-locations"
          />
        </SliderSectionInner>
      </SectionContainer>
    );
  };
  const query = graphql`
    query {
      allMarkdownRemark(
        limit: 1000
        sort: { fields: [frontmatter___date], order: ASC }
        filter: {
          frontmatter: {
            templateKey: { eq: "training-session" }
            draft: { ne: true }
          }
        }
      ) {
        totalCount
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              state
              image
              formId
              trainingEvents {
                trainingDates {
                  startDate
                  endDate
                }
              }
            }
          }
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
