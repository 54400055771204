import React from "react";
import GlobalLayout from "components/page/GlobalLayout";
import Helmet from "react-helmet";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import { StaticQuery, graphql } from "gatsby";
import RctpProgramAccordian from "components/RCTP-Program/RctpProgramAccordian";
import RhombusBlade from "components/common/RhombusBlade";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SlideInContainer from "components/common/SlideInContainer";
import UpComingTrainings from "components/RCTP-Program/UpComingTrainings";

export default function RctpProgram() {
  const renderContent = data => {
    const headerSection = {
      preTitle: "RCTP PROGRAM",
      title: "Become a Certified Rhombus Partner",
      description:
        "Our comprehensive Rhombus Certified Technical Professional (RCTP) Training Program equips you with the knowledge and skills to confidently recommend, design, and implement Rhombus security solutions for any project.",
      image: data.headerImage,
      button1: {
        text: "Browse Locations",
        path: "/rctp-program/training-sessions#event-locations",
      },
    };

    const trainingImage = getImage(data.trainingImage);

    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>Rhombus Certified Technical Professional Training</title>
          <meta
            name="description"
            content="Become a Rhombus technical expert with the knowledge and skills to confidently recommend, design, and implement Rhombus security solutions for any project."
          />
        </Helmet>
        <HeroLayout1 data={headerSection} />
        <SectionContainer>
          <SectionInner reverse>
            <SectionCol>
              <GatsbyImage
                image={trainingImage}
                alt="RCTP Training"
                style={{ height: "100%", width: "100%" }}
              />
            </SectionCol>
            <SectionCol style={{ alignItems: "center" }}>
              <TextContainer style={{ gap: "2.5rem" }}>
                <SlideInContainer>
                  <FancyTitleMed style={{ textAlign: "left" }}>
                    Rhombus Certified Technical Professional (RCTP) Training
                  </FancyTitleMed>
                </SlideInContainer>
                <MainParagraph>
                  Our intensive, one-day RCTP training program is your fast
                  track to mastering the entire Rhombus product line. Immerse
                  yourself in the intricate world of Rhombus cameras, access
                  control, sensors, alarms, and more. 
                </MainParagraph>
              </TextContainer>
            </SectionCol>
          </SectionInner>
        </SectionContainer>
        <RctpProgramAccordian />
        <UpComingTrainings />
        <RhombusBlade
          fancy
          reverse
          title="Becoming a Partner is Simple"
          blurb="Submit your application online in 5 minutes, and our Partner Team will reach out within two business days."
          button={{
            text: "Apply",
            path:
              "https://partners.rhombussystems.com/prm/English/c/Terms?__hstc=158651301.11b9a359e50e4a16823c8ed728407db3.1691694797463.1692043226007.1692120531447.10&__hssc=158651301.9.1692120531447&__hsfp=3529731725",
            useHref: true,
            target: "_blank",
          }}
        />
      </GlobalLayout>
    );
  };

  const GET_IMAGES = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/RCTP-Program/img/rctp-hero.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      trainingImage: file(
        relativePath: {
          eq: "components/RCTP-Program/img/training-session-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;

  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
